<template>
  <div class="daySign">
    <!-- <div class="daySign-t"><img src="./img/bg.png" alt="" /></div> -->
    <div class="top_box">
      <img src="./img/top_bg.png" />
    </div>
    <div class="daySign-c">
      <div class="body">
        <div class="daySign-cb">
          <div class="item" v-for="(item, index) in signData" :key="index">
            <div class="item-t">
              <div class="item-tl active" v-if="item.doSign && index != 6">
                <span>+{{ item.willGetScore }}</span>
              </div>
              <div class="item-tl" v-if="!item.doSign && index != 6">
                <span>+{{ item.willGetScore }}</span>
              </div>
              <div class="gift" v-if="index === 6">
                <img src="./img/gift_box.png" alt="" />
                <span>+{{ item.willGetScore }}</span>
              </div>
              <div class="item-tr" v-if="index != signData.length - 1"></div>
            </div>
            <div class="item-b">
              <div class="item-b-c">
                {{ item.doToday ? "今天" : item.showDate }}
              </div>
            </div>
          </div>
        </div>
        <div class="daySign-ct" v-if="postData.todayIsSignIn">
          已连续签到<span>{{ postData.continuousSignInCount }}</span
          >天
        </div>
        <div class="daySign-ct" v-else>
          已连续签到<span>{{ postData.continuousSignInCount }}</span
          >天
        </div>
        <div class="opera">
          <div class="opera-l" v-if="!postData.todayIsSignIn" @click="tosign">
            立即签到
          </div>
          <div class="opera-r" v-else>已签到</div>
        </div>
      </div>
    </div>
    <div class="daySign-b">
      <div class="body">
        <!-- 第三方不展示 -->
        <div v-if="origin != 'https://mapi.xydata.cc'">
          <div class="title">
            新手任务
            <div class="color_block"></div>
          </div>
          <div class="daySign-body">
            <div class="item">
              <div class="item-l">
                <div class="img"><img src="./img/icon1.png" alt="" /></div>
                <div class="txt">用户注册</div>
                <div class="block"></div>
                <div class="count">+{{ postData.userRegisterPoints }}</div>
              </div>
              <div class="item-r">
                <div class="opera" v-if="!postData.userIsRegister">去完成</div>
                <div class="opera opera-r" v-else>已完成</div>
              </div>
            </div>
            <div class="item">
              <div class="item-l">
                <div class="img"><img src="./img/icon2.png" alt="" /></div>
                <div class="txt">完善资料</div>
                <div class="block"></div>
                <div class="count">+{{ postData.userPerfectPoints }}</div>
              </div>
              <div class="item-r">
                <div
                  class="opera"
                  v-if="!postData.userIsPerfect"
                  @click="toFinished"
                >
                  去完成
                </div>
                <div class="opera opera-r" v-else>已完成</div>
              </div>
            </div>
          </div>
        </div>
        <div class="title title-b">
          每日任务
          <div class="color_block"></div>
        </div>
        <div class="daySign-body">
          <div class="item">
            <div class="item-l">
              <div class="img"><img src="./img/icon3.png" alt="" /></div>
              <div class="txt">每日签到</div>
              <div class="block"></div>
              <div class="count">+{{ postData.todayScore }}</div>
            </div>
            <div class="item-r">
              <div
                class="opera"
                v-if="!postData.todayIsSignIn"
                @click="everySign"
              >
                去完成
              </div>
              <div class="opera opera-r" v-else>已完成</div>
            </div>
          </div>
          <div class="item">
            <div class="item-l">
              <div class="img"><img src="./img/icon4.png" alt="" /></div>
              <div class="txt">运动打卡</div>
              <div class="block"></div>
              <div class="count">+{{ postData.userSportPoints }}</div>
            </div>
            <div class="item-r">
              <div
                class="opera"
                v-if="postData.userIsSport == 0"
                @click="toHealth"
              >
                去完成
              </div>
              <div
                class="opera"
                @click="toHealthList"
                v-if="postData.userIsSport == 1"
              >
                待审核
              </div>
              <div class="opera opera-r" v-if="postData.userIsSport == 2">
                已完成
              </div>
            </div>
          </div>
          <!-- <div class="item te" v-if="isShow == 1">
            <div class="item-l">
              <div class="img"><img src="./img/icon5.png" alt="" /></div>
              <div class="item-lr">
                <div class="item-lrt">
                  <div class="txt">每日评论</div>
                  <div class="block"></div>
                  <div class="count">+{{ postData.userCommentPoints }}</div>
                </div>
                <div class="item-lrb">
                  每日上限 <span>{{ postData.userCommentCount }}</span
                  >/{{ postData.userCommentCountMax }} 次
                </div>
              </div>
            </div>
            <div class="item-r">
              <div
                class="opera"
                @click="comment"
                v-if="postData.userCommentCount < postData.userCommentCountMax"
              >
                去完成
              </div>
              <div class="opera opera-r" v-else>已完成</div>
            </div>
          </div> -->
          <!-- <div class="item te" v-if="isShow == 1">
            <div class="item-l">
              <div class="img"><img src="./img/icon6.png" alt="" /></div>
              <div class="item-lr">
                <div class="item-lrt">
                  <div class="txt">每日点赞</div>
                  <div class="block"></div>
                  <div class="count">+{{ postData.userThumbsUpPoints }}</div>
                </div>
                <div class="item-lrb">
                  每日上限 <span>{{ postData.userThumbsUpCount }}</span
                  >/{{ postData.userThumbsUpCountMax }} 次
                </div>
              </div>
            </div>
            <div class="item-r">
              <div
                class="opera"
                @click="toZan"
                v-if="
                  postData.userThumbsUpCount < postData.userThumbsUpCountMax
                "
              >
                去完成
              </div>
              <div class="opera opera-r" v-else>已完成</div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { signStatusUrl, signUrl, dayMaskUrl, isShowSign } from "./api.js";
import { toRegister, gloabalCount } from "@/utils/common.js";
import wx from "weixin-js-sdk";
var moment = require("moment");
export default {
  name: "applyTalent",
  data() {
    return {
      signData: [],
      points: "",
      postData: {},
      signStatus: "",
      isShow: "",
      origin: "",
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    assetId() {
      return this.$store.state.assetId;
    },
  },
  created() {
    this.origin = window.location.origin;
    console.log(this.origin);
  },
  async mounted() {
    gloabalCount("", 603, 1);
    if (await toRegister(this.$route.path, this.$route.query, "每日签到")) {
      this.getMask();
    }
    this.isShowComment();
  },
  methods: {
    toHealthList() {
      this.$router.push({
        name: "healthList",
      });
    },
    toFinished() {
      let isRegister = 1;
      let _this = this;
      let assetId = this.assetId;
      wx.miniProgram.redirectTo({
        url: `/pages/register/index?isRegister=${isRegister}&assetId=${assetId}&isMust=1&ish5=1&toUrl=${
          _this.$route.path
        }&params=${JSON.stringify(_this.$route.query)}`,
      });
    },
    toZan() {
      this.$router.push({
        name: "partnerStyleList",
      });
    },
    comment() {
      this.$router.push({
        name: "partnerStyleList",
      });
    },
    everySign() {
      this.$toast({
        message: `当前页面签到即可`,
        duration: 2000,
      });
    },
    toHealth() {
      this.$router.push({
        name: "health",
      });
    },
    isShowComment() {
      let params = {
        applicationName: "每日签到",
        functionName: "显示评论点赞",
      };
      this.$axios
        .get(`${isShowSign}`, {
          params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.isShow = res.data.settingOn;
          } else {
          }
        });
    },
    getMask() {
      let params = {
        userId: this.userId,
        tenant: this.tenantId,
      };
      this.$axios
        .post(`${dayMaskUrl}`, this.$qs.stringify(params), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((res) => {
          this.signData = [];
          if (res.code === 200) {
            this.postData = res.data;
            console.log(this.postData);
            let oData = res.data.signData;
            if (oData.scoreList) {
              let arr = oData.scoreList.split("");
              console.log(arr, 345);
              arr.forEach((element, index) => {
                let obj = {};
                if (index < this.postData.continuousSignInCount) {
                  obj.doSign = true;
                } else {
                  obj.doSign = false;
                }
                obj.willGetScore = element;
                this.signData.push(obj);
              });
            }
          } else {
          }
        });
    },
    returnStatus(item, index) {
      if (index == this.signData.length - 1) {
        if (item.doSign) {
          return true;
        } else {
          return false;
        }
      } else {
        if (item.doSign && this.signData[index + 1].doSign) {
          return true;
        } else {
          return false;
        }
      }
    },
    async tosign() {
      if (await toRegister(this.$route.path, this.$route.query, "每日签到")) {
        this.sign();
      }
    },
    sign() {
      let params = {
        userId: this.userId,
        tenant: this.tenantId,
        communityId: this.communityId,
        roomId: this.roomId,
      };
      this.$axios
        .post(`${signUrl}`, this.$qs.stringify(params), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((res) => {
          if (res.code === 200) {
            this.points = res.data.pointCount;
            this.$toast({
              message: `恭喜你获得${this.points}积分`,
              duration: 2000,
            });
            this.getMask();
          } else {
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.daySign {
  min-height: 100vh;
  padding-bottom: 100px;
  background-color: #f9fbfc;

  .top_box {
    width: 100%;
    height: 612px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .daySign-t {
    padding: 10px 0 0 52px;

    img {
      width: 688px;
      height: 340px;
    }
  }

  .daySign-c {
    padding: 32px;
    margin-top: -295px;

    .body {
      // height: 256px;
      box-sizing: border-box;
      background: #ffffff;
      border-radius: 16px;
      position: relative;
      padding: 100px 72px 48px;

      .daySign-ct {
        font-size: 30px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        line-height: 36px;
        margin-top: 90px;
        margin-bottom: 18px;
        text-align: center;

        span {
          font-size: 36px;
          font-weight: bold;
          color: #f97f74;
          margin: 0 6px;
        }
      }

      .daySign-cb {
        display: flex;
        margin-top: 20px;
        .item-t {
          display: flex;
          align-items: center;

          .gift {
            margin-top: -15px;
            width: 54px;
            height: 46px;
            position: relative;

            img {
              width: 100%;
              height: 100%;
            }

            span {
              position: absolute;
              top: -32px;
              left: 50%;
              transform: translateX(-50%);
              font-size: 24px;
              font-weight: bold;
              color: #f97f74;
            }
          }

          .item-tl {
            width: 26px;
            height: 26px;
            background: #ffffff;
            border: 4px solid #fb877b;
            border-radius: 50%;
            box-sizing: border-box;
            position: relative;

            img {
              width: 100%;
              height: 100%;
            }

            span {
              position: absolute;
              top: -50px;
              left: 50%;
              transform: translateX(-50%);
              font-size: 24px;
              font-weight: 600;
              color: #f67e76;
            }
          }

          .active {
            background-color: #fb877b;
          }

          .item-tr {
            width: 38px;
            height: 4px;
            background: #f98075;
            border-radius: 2px;
            margin: 0 10px;
            box-sizing: border-box;
          }

          .active.item-tr {
            background: #007eff;
          }
        }

        .item-b {
          font-size: 24px;
          margin-top: 2px;
          font-weight: 400;
          color: #aaaaaa;

          line-height: 34px;

          .item-b-c {
            width: 56px;
            text-align: center;
          }
        }
      }

      .opera {
        .opera-l,
        .opera-r {
          width: 440px;
          height: 70px;
          border-radius: 16px;
          font-weight: bold;
          line-height: 70px;
          font-size: 32px;
          text-align: center;
          margin: 0 auto;
        }

        .opera-l {
          background: linear-gradient(180deg, #ffbfb8 0%, #fb8579 100%);
          color: #fff;
        }

        .opera-r {
          background: linear-gradient(180deg, #eeeeee 0%, #d8d8d8 100%);
          color: rgba(0, 0, 0, 0.25);
        }
      }
    }
  }

  .daySign-b {
    margin-top: 10px;
    padding: 0 32px;

    .body {
      background: #ffffff;
      border-radius: 16px;
      padding: 32px;

      .title {
        font-size: 36px;
        font-weight: bold;
        color: #1a1c34;
        line-height: 52px;
        margin-bottom: 16px;
        padding-left: 30px;
        position: relative;
        z-index: 10;
        .color_block {
          width: 196px;
          height: 18px;
          background: #e2e2e2;
          border-radius: 7px;
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: -1;
        }
      }

      .title-b {
        margin-top: 20px;
      }

      .item {
        padding: 36px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .item-l {
          display: flex;
          align-items: center;

          .img {
            width: 72px;
            height: 70px;
            margin-right: 16px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .txt,
          .count {
            font-size: 28px;
            font-weight: 400;
            color: #333333;
            line-height: 48px;
          }

          .block {
            width: 2px;
            height: 28px;
            background: #333333;
            opacity: 0.2;
            margin: 0 16px;
          }
        }

        .item-r {
          display: flex;
          align-items: center;

          .count {
            font-size: 28px;
            font-weight: bold;
            color: #666666;
            line-height: 40px;
            margin-right: 16px;
          }

          .opera {
            width: 168px;
            height: 60px;
            background: linear-gradient(180deg, #ffbdb6 0%, #fb887d 100%);
            border-radius: 30px;
            font-weight: bold;
            text-align: center;
            line-height: 60px;
            font-size: 30px;
            color: #ffffff;
          }

          .opera-r {
            background: linear-gradient(180deg, #eeeeee 0%, #d8d8d8 100%);
            color: rgba(0, 0, 0, 0.25);
          }
        }
      }

      .te {
        .item-l {
          .item-lrt {
            display: flex;
            align-items: center;
          }

          .item-lrb {
            font-size: 24px;
            font-weight: 400;
            color: #666666;
            line-height: 32px;
            margin-top: 8px;

            span {
              color: #f97f74;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.daySign {
}
</style>
